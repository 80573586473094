import { Component } from 'react'
import { withIdleTimer } from 'react-idle-timer'
import PropTypes from 'prop-types'

class IdleTimerComponent extends Component {

    static propTypes = {
        children: PropTypes.any
      }

    render() {
        return this.props.children
    }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent)