import React, { Component } from 'react'
import { ReactComponent as Auto } from '../assets/pictograms/auto.svg'
import PropTypes from 'prop-types'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static propTypes = {
    message: PropTypes.any,
    children: PropTypes.any
  }

  static getDerivedStateFromError = error => {
    return { hasError: true }
  }

  componentDidCatch = (error, errorInfo) => {
    // TODO
    // logErrorToMyService(error, errorInfo)
  }

  render = () => (this.state.hasError ? this.props.message || <div className="body emphasis-color displayFlexCentered"><Auto height="400px"/><h1>Something went wrong.</h1> </div> : this.props.children)
}
